<template>
	<div>
		<v-dialog v-model="showCreatedDialog"
			max-width="320"
		>
			<v-card color="primary" dark>
				<v-card-title><v-icon left>mdi-check-circle</v-icon>Product Created</v-card-title>
				<v-card-text>
					Your product has been added Successfully. Click view below to view product on AkokoMarket
				</v-card-text>
				<v-card-actions>
					<v-col cols="12">
						<v-row justify="end">
							<v-btn @click="showCreatedDialog = false" class="mr-2"><v-icon left>mdi-close</v-icon>Close</v-btn>
							<v-btn @click="$router.push('/')" color="white" class="primary--text"><v-icon left>mdi-eye</v-icon>View</v-btn>
						</v-row>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<DataTable
			v-if="show"
			:search_conditions="['name_contains', 'product_type.name_contains']"
			:getQuery="queries.get"
			:createQuery="queries.create"
			:removeQuery="queries.updateDelete"
			:updateQuery="queries.update"
			:conditions="conditions"
			:connector="connector"
			:validate="validate"
			:headers="headers"
			:createFields="createFields"
			icon="mdi-tag"
			result_name="products"
			title="product"
			subtitle="List of all products"
			@created="createdDialog"
		>
		</DataTable>
	</div>
</template>
<script>
	import {eventBus} from "../../../main";
	import DataTable from '@/components/datatable'
export default {
	components: {DataTable},
    data(){
		return {
			showCreatedDialog: false,
			show: false,
			user: this.$store.getters['auth/getUserData'],
			queries: require('@/gql/product.gql'),
			conditions: {},
			connector: {},
			productTypes: [],
			createFields: [],
			validate: ['img_url'],
			headers: [
				{ text: 'Image', value: 'avatar' },
				{ text: 'Product Name *', align: 'left', value: 'name' },
				{ text: 'Product Type', align: 'left', value: 'product_type.name' },
				{ text: 'Category', value: 'category.name', align : 'left' },
				{ text: 'Index', value: 'index', align : 'left' },
				{ text: 'Price (GHc)', value: 'price', align : 'left' },
				{ text: 'Weight', value: 'weight', align : 'left' },
				{ text: 'Date Added', value: 'createdAt', align : 'left' },
				{ text: 'Actions', value: 'action', sortable: false },
			],
		}
	},
	methods:{
		createdDialog(){
			this.showCreatedDialog = true
		},
		productOnChange(field, editedItem, createFields){
			createFields[1].items.forEach(productType=>{
				if(productType.value === editedItem[field.name]){
					eventBus.$emit("setProductSlug",productType.slug);
					createFields[2].items = productType.subs
				}
			})
		},
		initConditions(){
			const company_id = this.user.company.id
			this.conditions = {
				// company: {
				// 	id: company_id,
				// },
				// // status: "active"

			}
			this.connector = {
				company: {
					connect: {
						id: company_id
					}
				}
			}
		}
	},
	created(){
		this.initConditions()
    console.log('the actor',this.user)
		this.$apollo.query({
			query: require('@/gql/productType.gql').get,
		}).then(res=>{
			this.productTypes = res.data.productTypes.map(type=>({
				text: type.name,
				value: type.id,
				slug: type.slug,
				subs: type.categories.map(category=>({text: category.name, value: category.id}))
				}))
			this.createFields = [
				{ name:'name', label: 'Product Name*', max: 30, icon: 'mdi-tag'},
				{ name:'product_type.connect.id',
					type: 'select',
					label: 'Product Type*',
					icon: 'mdi-tag-heart',
					onChange: this.productOnChange,
					items: this.productTypes
				},
				{ name:'category.connect.id', type: 'select', label: 'Product Categories*',
					icon: 'mdi-tag-text',
					items: []
				},
        // { name: 'index', label: 'Index', type: 'number', icon: 'mdi-tag'},
				{ name:'price', label: 'Price (GHc)', min: 1, type:'float',  icon: 'mdi-cash'},
				{ name:'status', label: 'Status', type: 'select', icon: 'mdi-tag',
          items: ['active', 'inactive', 'promoted', 'deleted']
        },
				{ name:'weight', width: "6", label: 'Weight*', min:1, type:'number', icon: 'mdi-weight'},
				{ name:'units', width: "6", type: 'select', label: 'Units',
					icon: 'mdi-playlist-check',
					items: ['kg','g','lb', 'Crate', 'Bag(50kg)', 'Bag(100kg)']
				},
				{ name:'usage', label: 'Usage (eg. Home use, Restaurants, etc)', icon: 'mdi-waves'},
				{ name:'minimum', label: 'Minimum Orders', icon: 'mdi-numeric-9-plus-circle'},
				{ name:'img_url', label: 'Upload image', type:'upload', icon: 'mdi-image'},

			]
			this.show = true
		}).catch(err=>{
			console.error(err)
			this.$error('cant fetch product Types')
		})
	}
}
</script>
